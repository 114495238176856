<script>
  import api from "../../api";
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    components: { VueSlickCarousel },
    data: function () {
      return {
        photosList: [],
        cpage: 1,
        morepage: false,
        loading: true,
        visor: null,
        filtered: null,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          mobileFirst: true,
          infinite: true,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
              },
            },
          ],
        },
      };
    },
    computed: {
      lang() {
        return localStorage.getItem("user-language") || "es";
      },
      photos() {
        return this.photosList || [];
      },
      filter() {
        const { filter = "" } = this.$route.query;
        return filter;
      },
      query() {
        let query = `page=${this.cpage}`;

        if (this.filter) {
          query += `&filter=${this.filter}`;
        }
        return query;
      },
    },
    watch: {
      async filter() {
        this.cpage = 1;
        const resPhotos = await this.getPhotos();
        this.photosList = resPhotos;
      },
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getPhotos: async function () {
        const { data } = await api.get(`${process.env.VUE_APP_API_HOST}gallery?${this.query}`);
        const { pictures, filtered, isMorePages, remFilter } = data;

        this.filtered = filtered ? filtered.hashtagText[this.lang] : null;
        this.morepage = isMorePages;
        this.rmbtn = remFilter;

        this.loading = false;
        return pictures?.picturesAlbum || [];
      },
      async getMorePhotos() {
        this.cpage += 1;

        const resPhotos = await this.getPhotos();

        this.photosList.push(...resPhotos);
      },
      async checkIfLastSlide(currentSlide) {
        if (currentSlide === this.photosList.length - 2 && this.morepage) {
          await this.getMorePhotos(currentSlide);
          this.settings.initialSlide = currentSlide;
        }
      },
      photoView({ action, idx }) {
        const mbody = document.querySelector("body");
        const isOpen = action === "open";

        if (isOpen) {
          this.settings.initialSlide = idx;
          setTimeout(function () {
            const elmn = document.querySelector(".photo-gallery-visor .slick-slide");
            elmn.focus();
          }, 100);
        }

        mbody.classList[isOpen ? "add" : "remove"]("onphoto");
        this.visor = isOpen;
      },
    },
    async beforeMount() {
      this.photosList = await this.getPhotos();
    },
  };
</script>

<template>
  <div class="photo-gallery">
    <div class="photos-list relative" :class="{ loading: loading }">
      <div class="photos-list-title" v-if="this.filtered && this.filter !== 'all'">
        <span dcolor>{{ this.filtered }}</span>
        <span>
          <router-link to="/gallery#gallery" v-if="this.rmbtn">
            <span bold>{{ this.rmbtn }}</span>
          </router-link>
        </span>
      </div>
      <div class="main">
        <div class="photos-list-container" v-if="photos">
          <div class="photos-list-container-wrap">
            <div class="photos-list-container-photo" v-for="(photo, pidx) in photos" :key="pidx">
              <div class="photos-list-container-photo-wrap">
                <img @click="photoView({ action: 'open', idx: pidx })" :src="urlCloudFront(photo.pictureLink)" alt="photo.alt" />
              </div>
            </div>
            <div class="load-more-block relative" :class="{ loading: loading }" v-if="morepage">
              <div class="load-more-block-content" v-if="!loading">
                <button class="click-on-bottom" @click="getMorePhotos">Ver mas</button>
              </div>
              <div class="onScrollLoading preventStayOnBottom" v-else-if="loading"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="photo-gallery-visor" v-if="visor">
      <VueSlickCarousel v-bind="settings" class="carrousel centered" @afterChange="checkIfLastSlide">
        <div class="photo-gallery-visor-img" v-for="(photo, pidx) in photos" :key="pidx" prevent>
          <div class="photo-gallery-visor-img-title">{{ photo.alt }}</div>
          <img class="animated-fast fadeIn" :src="photo.pictureLink" :alt="photo.alt" prevent />
        </div>
      </VueSlickCarousel>
      <a class="photo-gallery-visor-close-btn pointer" @click="photoView({ action: 'close' })">
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  body.onphoto {
    .photos-list {
      visibility: hidden;
    }
  }
  .photo-gallery {
    margin-top: 40px;
    .loading {
      &::before {
        display: none;
      }
      &::after {
        width: 18px;
        height: 18px;
        border-width: 3px;
      }
    }
    .photos-list {
      &-title {
        padding: 0 $mpadding $mpadding $mpadding;
        span {
          display: inline-block;
          &:nth-child(1) {
            margin-right: 5px;
          }
        }
        a {
          &:hover {
            border-bottom: solid 1px;
          }
        }
      }
      &-container {
        width: 100%;
        &-wrap {
          padding: 0 $mpadding/2;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
        }
        &-photo {
          float: left;
          width: 100%;
          &-wrap {
            width: 100%;
            margin: $mpadding/2;
            width: calc(100% - #{$mpadding});
            height: calc(100% - #{$mpadding});
          }
          img {
            width: 100%;
            height: 100%;
            cursor: zoom-in;
          }
        }
      }
      .load-more-block {
        display: inline-block;
        width: 100%;
        margin-top: $mpadding;
      }
    }
    &-visor {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      backdrop-filter: blur(10px);
      z-index: 999;
      &-img {
        position: relative;
        &-title {
          margin-bottom: $mpadding/2;
          color: $white;
          text-shadow: 0 0 $mpadding * 0.668 #000;
        }
        img {
          max-width: 100%;
          width: auto;
          max-height: 100vh;
          margin: 0 auto;
        }
      }
      &-close-btn {
        position: fixed;
        top: $mpadding;
        right: $mpadding;
        width: 44px;
        height: 44px;
        line-height: 44px;
        z-index: 2;
        color: $white;
        background-color: $primary_color_50;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: $primary_color;
        }
      }
      .slick-track {
        display: flex;
        align-items: center;
        height: 100%;
        overflow-y: auto;
      }
      .slick-track:active {
        cursor: move;
      }
      .slick-prev,
      .slick-next {
        z-index: 1;
        height: 100%;
        width: $mpadding * 4;
        position: fixed;
        top: 50%;
      }
      .slick-prev::before,
      .slick-next::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .slick-prev.slick-disabled:before,
      .slick-next.slick-disabled:before {
        opacity: 0;
      }
      .slick-prev {
        left: 0;
        padding-left: $mpadding;
      }
      .slick-next {
        right: 0;
        padding-right: $mpadding;
      }
      .slick-prev::before {
        background: url("/img/slide-left-hand.svg") no-repeat center;
      }
      .slick-next::before {
        background: url("/img/slide-right-hand.svg") no-repeat center;
      }
    }
    @media (min-width: $tablet_width) {
      .photos-list {
        &-container {
          &-wrap {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
      &-visor {
        &-img {
          position: relative;
          &-title {
            position: absolute;
            top: $mpadding;
            left: $mpadding;
          }
        }
      }
    }
  }
</style>
